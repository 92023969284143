import { DialogService } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { TableHeader } from 'components/table/table-content';
import {
  CreateTemplateDialogArgs,
  MooringCreateUpdateTemplateDialog,
  UpdateTemplateDialogArgs,
} from 'features/mooring/component-templates/mooring-create-template-dialog';
import { AsyncErrorHandler } from 'lib/ui';
import { MooringTemplateListItemDto } from 'models/mooring';
import { MooringComponentTemplateService } from 'services/mooring/mooring-component-temlate-service';
import { PubSub } from 'lib/event/PubSub';
import { Filters } from 'elements/Filter';
import { ConfirmDialog } from 'components/dialogs/confirm/confirm-dialog';
import { ToastService } from 'services';
import { FilterManager } from 'lib/tables/FilterManager';

@autoinject
export class GridLinesTab {
  protected tableHeaders: TableHeader<MooringTemplateListItemDto & { actions: '' }>[] = [
    { key: 'Id', label: 'general.id', sortable: true },
    { key: 'Name', label: 'general.name', sortable: true },
    { key: 'Created', label: 'general.created', sortable: true },
    { key: 'CreatedBy', label: 'general.createdBy', sortable: true },
    { key: 'actions', label: '', sortable: false },
  ];

  templates: MooringTemplateListItemDto[] = [];
  /**
   *
   */
  constructor(
    protected element: Element,
    private mooringTempateService: MooringComponentTemplateService,
    private dialogController: DialogService,
    private pubsub: PubSub,
    private fm: FilterManager,
    private confirmationDialog: ConfirmDialog,
    private toast: ToastService
  ) {
    pubsub.sub('filter-manager:filter-changed', () => {
      this.getTemplates();
    });

    pubsub.sub('list-entity:create', () => {
      void this.createTemplate();
    });
  }

  async attached() {
    this.fm.init({
      context: GridLinesTab.name,
      storageKey: 'grid-lines-templates-table',
      filters: {} satisfies Filters,
      defaultFilters: {},
    });
  }

  protected setOrderByColumn(column: string) {
    this.fm.setOrderByColumn(column);
  }

  @AsyncErrorHandler
  protected async getTemplates() {
    const { data, headers } = await this.mooringTempateService.getGridLineTemplates(this.fm.queryFilter);
    this.fm.paginationTotalRecords = +headers.get('x-total-count') || 0;
    this.templates = data ?? [];
  }

  protected async openTemplate(index: number) {
    this.dialogController
      .open({
        viewModel: MooringCreateUpdateTemplateDialog,
        position: () => 0,
        model: {
          action: 'update',
          type: 'grid-line',
          templateId: this.templates.at(index).Id,
        } satisfies UpdateTemplateDialogArgs,
      })
      .whenClosed(() => this.getTemplates());
  }

  protected async createTemplate() {
    await this.dialogController
      .open({
        viewModel: MooringCreateUpdateTemplateDialog,
        position: () => 0,
        model: {
          action: 'create',
          type: 'grid-line',
          components: [],
        } satisfies CreateTemplateDialogArgs,
      })
      .whenClosed(() => this.getTemplates());
  }

  @AsyncErrorHandler
  protected async deleteTemplate(index: number) {
    const id = this.templates.at(index)?.Id;
    if (!id) return;
    const res = await this.confirmationDialog.confirmDelete();
    if (!res) return;
    await this.mooringTempateService.deleteGridLineTemplate(id);
    await this.getTemplates();
    this.toast.showDeleted();
  }

  protected detached() {
    this.pubsub.unsub();
  }

  unbind() {
    this.pubsub.unsub();
  }
}
