import { autoinject } from 'aurelia-framework';
import { renderMap } from './renderer';
import { MooringService } from 'services/mooring';

@autoinject
export class MooringMapView {
  private mooringId: number;

  private windowBus = new BroadcastChannel('mooring_map_bus');

  constructor(private mooringService: MooringService) {
    //
  }

  protected activate({ mooringId }: { mooringId: number }) {
    this.mooringId = mooringId;
    void this.renderMap();

    this.windowBus.onmessage = (e) => {
      if (!e.isTrusted) return;
      if (e.data['map'] === 'update') {
        this.starReRender();
      }
    };
  }

  protected timer = 0;
  protected starReRender() {
    window.clearTimeout(this.timer);
    this.timer = window.setTimeout(() => {
      this.renderMap();
    }, 500);
  }

  protected async renderMap() {
    const { data } = await this.mooringService.getMooringDrawingSetup(this.mooringId);
    renderMap(data);
  }
}
