import { CageDrawingPropertyDto } from 'models/mooring';

type CageOutput = {
  Id: number;
  Row: number;
  Column: number;
  Diameter: number;
  Label: string;
  Center: { x: number; y: number };
};

type GenerateCageStructureArgs = {
  cages: CageDrawingPropertyDto[];
};

const DIAMETER = 120;
const OFFSET_X = 120;
const OFFSET_Y = 60;

export function createCageStructure({ cages }: GenerateCageStructureArgs) {
  return cages.map(
    (x) =>
      ({
        Column: x.Column,
        Row: x.Row,
        Id: x.Id,
        Diameter: DIAMETER,
        Label: x.MooringNo,
        Center: { x: (x.Column - 1) * OFFSET_X, y: 30 - (x.Row - 1) * OFFSET_Y },
      }) satisfies CageOutput
  );
}
