import { Fill, Stroke, Style, Text } from 'ol/style';

export function gridLineStyle(name: string, isAccident: boolean) {
  return new Style({
    stroke: new Stroke({ color: isAccident ? '#ffd27f' : '#437c8d', width: 4 }),

    text: new Text({
      text: name ?? '??',
      font: '14px Arial, sans-serif',
      fill: new Fill({ color: '#a8c6ce' }),
      backgroundFill: new Fill({ color: '#0e4959' }),
      padding: [4, 4, 4, 4],
    }),
  });
}
