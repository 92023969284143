import { BuoyDrawingPropertyDto } from 'models/mooring';

export type BuoyMappedForDrawing = {
  Id: number;
  Row: number;
  Column: number;
  Diameter: number;
  Center: { x: number; y: number };
};

type GenerateBuoyStructureArgs = {
  buoys: BuoyDrawingPropertyDto[];
};

const DIAMETER = 5;
const OFFSET = 60;

export function createBuoyStructure({ buoys }: GenerateBuoyStructureArgs) {
  return buoys.map((x) => {
    const xPos = -OFFSET + 120 * (x.Column - 1);
    const yPos = -OFFSET + 60 * (x.Row - 1);

    return {
      Column: x.Column,
      Row: x.Row,
      Id: x.Id,
      Diameter: DIAMETER,
      Center: { x: xPos, y: -yPos },
    } satisfies BuoyMappedForDrawing;
  });
}
