import { autoinject, PLATFORM } from 'aurelia-framework';
import { ViewLoadingFader } from 'lib/ui';
import { MooringDocumentsHandler } from 'services/attachments';

import './template-view.scss';
import { PubSub } from 'lib/event/PubSub';
import { Tab } from 'elements/tabs/MnTabs';
import { I18N } from 'aurelia-i18n';

@autoinject
export class TemplateView {
  protected analysisAttachmentHandler: MooringDocumentsHandler;

  protected tabs: Tab[] = [
    {
      name: 'anchor-lines',
      title: this.t.tr('mooring.anchorLine_plural'),
      view: PLATFORM.moduleName('views/mooring/mooring-component-templates/tabs/anchor-lines-tab'),
    },
    {
      name: 'grid-lines',
      title: this.t.tr('mooring.gridLine_plural'),
      view: PLATFORM.moduleName('views/mooring/mooring-component-templates/tabs/grid-lines-tab'),
    },
    {
      name: 'buoys',
      title: this.t.tr('mooring.buoy_plural'),
      view: PLATFORM.moduleName('views/mooring/mooring-component-templates/tabs/buoys-tab'),
    },
    {
      name: 'bridles',
      title: this.t.tr('mooring.bridle_plural'),
      view: PLATFORM.moduleName('views/mooring/mooring-component-templates/tabs/bridles-tab'),
    },
  ];

  constructor(
    protected element: Element,
    private t: I18N,
    private pubSub: PubSub
  ) {}

  protected createNewTemplate() {
    this.pubSub.publish('list-entity:create', null);
  }

  @ViewLoadingFader
  async attached() {
    //
  }
}
