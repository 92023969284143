import { BuoyDrawingPropertyDto } from 'models/mooring';
import { buoyStyle } from './styles';
import { createBuoyStructure } from './transform';
import VectorSource from 'ol/source/Vector';
import { scaleCoordinates } from '../helpers';
import Feature from 'ol/Feature';
import { Circle as CircleGeometry } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import { Coordinate } from 'ol/coordinate';

export { BuoyMappedForDrawing } from './transform';

// Draw buoys

type RenderBuoysProps = {
  buoys: BuoyDrawingPropertyDto[];
  vectorSource: VectorSource;
  center: Coordinate;
};
export const renderBuoys = ({ buoys, vectorSource, center }: RenderBuoysProps) => {
  const mappedBuoys = createBuoyStructure({ buoys });

  mappedBuoys.forEach((buoy) => {
    const baseBuoy = buoys.find((x) => x.Id === buoy.Id);
    const position = scaleCoordinates(buoy.Center, center);
    const buoyFeature = new Feature({
      geometry: new CircleGeometry(fromLonLat(position), 10),
      type: 'buoy',
      id: buoy.Id,
    });
    buoyFeature.setStyle(buoyStyle(baseBuoy.MooringNo));
    vectorSource.addFeature(buoyFeature);
  });

  return mappedBuoys;
};
