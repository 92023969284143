import { Fill, Stroke, Style, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';

export function achorLineStyle(isAccident: boolean) {
  return new Style({
    stroke: new Stroke({ color: isAccident ? '#ffd27f' : '#437c8d', width: 4 }),
  });
}

export function achorLineLabelStyle(name?: string) {
  return new Style({
    image: new CircleStyle({
      radius: 16,
      fill: new Fill({ color: '#ffffff' }),
      stroke: new Stroke({ color: '#437c8d', width: 1 }),
    }),
    text: new Text({
      text: name,
      font: '14px Arial, sans-serif', // Font size and family,
      placement: 'point',
      textAlign: 'center',
      textBaseline: 'middle',
    }),
  });
}
