import { AnchorLineDrawingPropertyDto } from 'models/mooring';
import VectorSource from 'ol/source/Vector';
import { angleToRadians, getSpreadAngleForN, scaleCoordinates } from '../helpers';
import Feature from 'ol/Feature';
import { LineString, Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import { BuoyMappedForDrawing } from '../buoy';
import { achorLineLabelStyle, achorLineStyle } from './styles';
import { Coordinate } from 'ol/coordinate';

// Draw anchorLines

type RenderAnchorLinesProps = {
  anchorLines: AnchorLineDrawingPropertyDto[];
  vectorSource: VectorSource;
  buoys: BuoyMappedForDrawing[];
  rows: number;
  columns: number;
  center: Coordinate;
};

const ANCHOR_LINE_LENTH = 0.0007;

export const renderAnchorLines = ({ anchorLines, buoys, vectorSource, rows, columns, center }: RenderAnchorLinesProps) => {
  const anchorLinesGroupedByBuoy = anchorLines.reduce((acc, current) => {
    let lines = acc.get(current.BuoyId);
    if (!lines) {
      lines = [];
      acc.set(current.BuoyId, lines);
    }
    lines.push(current);
    return acc;
  }, new Map<number, AnchorLineDrawingPropertyDto[]>());

  for (const [buoyId, lines] of Array.from(anchorLinesGroupedByBuoy.entries())) {
    const linesConnected = lines.length;

    lines.forEach((line, lineIndex) => {
      const buoy = buoys.find((b) => b.Id === buoyId);
      if (!buoy) return;

      const start = scaleCoordinates(buoy.Center, center);
      const end = scaleCoordinates(buoy.Center, center);

      const isFirstRow = buoy.Row === 1;
      const isEndRow = buoy.Row === rows;

      const isFirstColumn = buoy.Column === 1;
      const isEndColumn = buoy.Column === columns;

      let angleDegrees = 0;
      if (isFirstRow) {
        if (isFirstColumn) {
          angleDegrees = 135;
        } else if (isEndColumn) {
          angleDegrees = 45;
        } else {
          angleDegrees = 90;
        }
      } else if (isEndRow) {
        if (isFirstColumn) {
          angleDegrees = 225;
        } else if (isEndColumn) {
          angleDegrees = 315;
        } else {
          angleDegrees = 270;
        }
      } else {
        if (isFirstColumn) {
          angleDegrees = 180;
        } else if (isEndColumn) {
          angleDegrees = 0;
        } else {
          angleDegrees = 20;
        }
      }

      const angleOffset = getSpreadAngleForN(lineIndex, linesConnected);
      const angleRadians = angleToRadians(angleDegrees, angleOffset);

      const x1 = end[0];
      const y1 = end[1];

      end[0] = x1 + ANCHOR_LINE_LENTH * Math.cos(angleRadians);
      end[1] = y1 + ANCHOR_LINE_LENTH * Math.sin(angleRadians);

      const lineFeature = new Feature({
        geometry: new LineString([fromLonLat(start), fromLonLat(end)]),
        type: 'anchorLine',
        id: line.Id,
      });

      lineFeature.setStyle(achorLineStyle(line.IsAccident));
      vectorSource.addFeature(lineFeature);

      const labelFeature = new Feature({
        geometry: new Point(fromLonLat(end)),
        type: 'anchorLineLabel',
        id: line.Id,
      });

      labelFeature.setStyle(achorLineLabelStyle(line.MooringNo));
      vectorSource.addFeature(labelFeature);
    });
  }
};
