import { Fill, Stroke, Style, Text } from 'ol/style';

export function cageStyle(label: string, options?: { hover: boolean }): Style {
  return new Style({
    fill: new Fill({
      color: options?.hover ? '#003444' : '#06232d',
    }),
    stroke: new Stroke({
      color: '#0e4959',
      width: 2, // Border width
    }),
    text: new Text({
      font: '14px Arial, sans-serif', // Font size and family
      text: label, // The label to display
      fill: new Fill({
        color: 'white', // Text color
      }),
      textAlign: 'center', // Align text to the center
      textBaseline: 'middle', // Vertically center the text
    }),
  });
}
