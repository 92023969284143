import 'ol/ol.css';
import OlMap from 'ol/Map';
import View from 'ol/View';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { renderCages } from './cage';
import { renderBuoys } from './buoy';
import { renderGridLines } from './gridLine';
import { MooringDrawingSetup } from 'models/mooring';
import { renderAnchorLines } from './anchorLine';
import TileLayer from 'ol/layer/Tile';
import { OSM } from 'ol/source';
import { convertToDecimalDegrees } from './helpers';
import { fromLonLat } from 'ol/proj';

let map: OlMap;
// Map setup
export const renderMap = (data: MooringDrawingSetup) => {
  const center = [convertToDecimalDegrees(data.CenterPositionLong), convertToDecimalDegrees(data.CenterPositionLat)];

  if (map) {
    const layers = map.getLayers().getArray();
    for (const layer of layers) {
      if (layer.getClassName() !== 'mooring') continue;
      map.removeLayer(layer);
    }
    const v = map.getView();
    v.setCenter(fromLonLat(center));
    const target = document.getElementById('map');
    map.setTarget(target);
  } else {
    map = new OlMap({
      target: 'map',
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: fromLonLat(center),
        zoom: 17,
      }),
    });
  }

  const vectorSource = new VectorSource();
  const vectorLayer = new VectorLayer({ source: vectorSource, className: 'mooring' });
  map.addLayer(vectorLayer);

  const rows = data.Buoys.map((x) => x.Row)
    .sort()
    .at(-1);

  const columns = data.Buoys.map((x) => x.Column)
    .sort()
    .at(-1);

  const cages = renderCages({ vectorSource, cages: data.Cages, rows, columns, center });
  const buoys = renderBuoys({ vectorSource, buoys: data.Buoys, center });
  renderGridLines({ buoys, vectorSource, gridLines: data.GridLines, center });
  renderAnchorLines({ columns, buoys, rows, vectorSource, anchorLines: data.AnchorLines, center });

  // map.getView().fit(vectorSource.getExtent(), {
  //   size: map.getSize(),
  //   padding: [50, 50, 50, 50],
  //   maxZoom: 18,
  // });

  // Add hover and click interactivity
  // let lastHoveredFeature: Feature | null = null;

  // map.on('pointermove', (event) => {
  //   // Reset the style of the previously hovered feature
  //   if (lastHoveredFeature) {
  //     const label = `Cage ${lastHoveredFeature.get('id')}`;
  //     lastHoveredFeature.setStyle(cageStyle(label));
  //     lastHoveredFeature = null;
  //   }
  //
  //   // Get the feature under the mouse
  //   map.forEachFeatureAtPixel(event.pixel, (feature) => {
  //     if (!(feature instanceof Feature)) return;
  //
  //     if (feature.get('type') === 'cage') {
  //       feature.setStyle(cageStyle('123', { hover: true })); // Apply hover style
  //       lastHoveredFeature = feature; // Track the hovered feature
  //     }
  //     return true; // Stop iteration after finding the first feature
  //   });
  // });

  // map.on('click', (event) => {
  //   map.forEachFeatureAtPixel(event.pixel, (feature) => {
  //     const type = feature.get('type');
  //     const id = feature.get('id');
  //     alert(`${type} clicked! ID: ${id}`);
  //     return true;
  //   });
  // });
};
