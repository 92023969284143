import { fromLonLat } from 'ol/proj';
import { scaleCoordinates } from '../helpers';
import { cageStyle } from './styles';
import { createCageStructure } from './transform';
import Feature from 'ol/Feature';
import { Circle as CircleGeometry } from 'ol/geom';
import { CageDrawingPropertyDto } from 'models/mooring';
import VectorSource from 'ol/source/Vector';
import { Coordinate } from 'ol/coordinate';

type RenderCagesProps = {
  cages: CageDrawingPropertyDto[];
  vectorSource: VectorSource;
  rows: number;
  columns: number;
  center: Coordinate;
};
export const renderCages = ({ cages, vectorSource, center }: RenderCagesProps) => {
  const mappedCages = createCageStructure({ cages });
  // Draw cages
  mappedCages.forEach((cage) => {
    const cageCenter = scaleCoordinates(cage.Center, center);
    const circleFeature = new Feature({
      geometry: new CircleGeometry(fromLonLat(cageCenter), cage.Diameter / 2),
      type: 'cage',
      id: cage.Id,
    });
    circleFeature.setStyle(cageStyle(cage.Label));
    vectorSource.addFeature(circleFeature);
  });

  const circleFeature = new Feature({
    geometry: new CircleGeometry(fromLonLat(center), 20),
    type: 'cage',
    id: '123',
  });
  circleFeature.setStyle(cageStyle(`center`));
  vectorSource.addFeature(circleFeature);
  return mappedCages;
};
