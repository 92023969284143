export function scaleCoordinates(coord: { x: number; y: number }, center: number[]): [number, number] {
  const scale = 0.00001; // Adjust this scale for proper rendering
  return [coord.x * scale + center[0], coord.y * scale + center[1]];
}

/**
 * Returns the given angel in degrees as radians.
 * Optionally provide an offset
 */
export function angleToRadians(angleDegrees: number, angleOffset = 0) {
  return ((angleDegrees + angleOffset) * Math.PI) / 180;
}

/**
 * Returns the spread angle for N entries, for given N.
 * This is to get an angle offset based on the Nth entry.
 * For three point that would normally be rendered at the same location, this will return
 * the spread for the points so that they can be rendered away from each other.
 * E.g. for three lines that starts and ends at the same position. This can be used to
 * get a spred at the end, so that the first line gets -15 degrees, the second with 0 degrees, and last +15 degrees.
 */
export function getSpreadAngleForN(n: number, entries: number, spread = 15) {
  if (entries <= 1) return 0;
  return ((n - Math.floor(entries / 2)) / (entries - 1)) * (spread * entries);
}

export function convertToDecimalDegrees(coord: string): number {
  if (!coord) return 0;
  coord = coord.replaceAll('.', ',');
  const regex = /(\d+)°(\d+(?:,\d+)?)'/;
  const match = coord.match(regex);

  if (!match) {
    return 0;
  }

  const degrees = parseFloat(match[1]);
  const minutes = parseFloat(match[2].replace(',', '.')); // Replace comma with a dot for parsing
  return degrees + minutes / 60;
}
