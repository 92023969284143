import { fromLonLat } from 'ol/proj';
import Feature from 'ol/Feature';
import { scaleCoordinates } from '../helpers';
import { gridLineStyle } from './styles';
import { LineString } from 'ol/geom';
import { GridLineDrawingPropertyDto } from 'models/mooring';
import VectorSource from 'ol/source/Vector';
import { BuoyMappedForDrawing } from '../buoy';
import { Coordinate } from 'ol/coordinate';

type RenderGridLinesProps = {
  gridLines: GridLineDrawingPropertyDto[];
  buoys: BuoyMappedForDrawing[];
  vectorSource: VectorSource;
  center: Coordinate;
};
export const renderGridLines = ({ gridLines, buoys, vectorSource, center }: RenderGridLinesProps) => {
  // Draw grid lines
  gridLines.forEach((line) => {
    const buoy1 = buoys.find((b) => b.Id === line.Buoys[0]);
    const buoy2 = buoys.find((b) => b.Id === line.Buoys[1]);

    if (buoy1 && buoy2) {
      const start = scaleCoordinates(buoy1.Center, center);
      const end = scaleCoordinates(buoy2.Center, center);
      const lineFeature = new Feature({
        geometry: new LineString([fromLonLat(start), fromLonLat(end)]),
        type: 'gridLine',
        id: line.Id,
      });
      lineFeature.setStyle(gridLineStyle(line.MooringNo, line.IsAccident));
      vectorSource.addFeature(lineFeature);
    }
  });
};
