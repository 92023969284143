import { DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import {
  CertificateProducer,
  CreateUpdateMooringCertificate,
  MooringArticle,
  MooringCertificate,
  MooringCertificateReliabilityClass,
  categoryDimensionTypes,
} from 'models/mooring';
import { SupplierService } from 'services/supplier-service';
import { MooringArticleProductService, MooringCertificateService } from 'services';
import { ErrorService } from 'services/error-service';
import { CertificateMetadataHeader } from './certificate-metadata-row';
import { ToastService } from 'services/toast-service';
import { I18N } from 'aurelia-i18n';
import { ConfirmDialog } from 'components/dialogs/confirm/confirm-dialog';
import { connectTo } from 'aurelia-store';
import { ApplicationState } from 'lib/state';
import { PubSub } from 'lib/event/PubSub';
import { PdfPreviewController } from '../../../elements/pdf-preview-controller';

export type CertificateFormData = {
  Id?: number;
  ArticleProductId: number;

  SupplierId: number;

  BatchNumber: string;
  BatchNumberTo: string;

  InternalOrderNumber: string;

  DateOfGoodsReceipt: Date;
  Quantity: number;

  MooringStationId: number;

  ReliabilityClass: number;

  TemplateId?: number;
  ProducerDocumentationAttachmentId?: number;
  ProducerDocumentationAttachment?: File | undefined;
  CertificateAttachmentId?: number;
  CertificateAttachment?: File | undefined;
  OverrideCertificateProducer?: CertificateProducer;
};

@connectTo()
@autoinject
export class CertificateDialog {
  protected isLocked: boolean = false;
  protected isEditingMetadata: boolean = true;
  protected isMorenotCertificate: boolean = false;

  protected certificate: MooringCertificate = new MooringCertificate();

  protected certificateProducer: CertificateProducer;
  protected article: MooringArticle;

  protected metadata: CertificateMetadataHeader;
  protected form: Partial<CertificateFormData> = {
    ReliabilityClass: MooringCertificateReliabilityClass.II,
  };

  protected isSaving = false;

  state: ApplicationState;

  constructor(
    private dialogController: DialogController,
    private supplierService: SupplierService,
    private mooringArticleProductService: MooringArticleProductService,
    private mooringCertificateService: MooringCertificateService,
    private errorService: ErrorService,
    private toastService: ToastService,
    private t: I18N,
    private confirmation: ConfirmDialog,
    private pubSub: PubSub,
    private pdfPreviewController: PdfPreviewController
  ) {}

  get approvalText() {
    if (this.isLocked) {
      return `${this.t.tr('general.certificate')} ${this.t.tr('general.approved').toLowerCase()}`;
    }
    return `${this.t.tr('general.approve')} ${this.t.tr('general.certificate').toLowerCase()}`;
  }

  get previewText() {
    return `${this.t.tr('general.preview')} ${this.t.tr('general.certificate').toLowerCase()}`;
  }

  get unlockText() {
    return `${this.t.tr('general.unlockCertificate')}`;
  }

  protected previewProgress = false;
  protected async previewCertificate() {
    try {
      this.previewProgress = true;
      const blob = await this.mooringCertificateService.preview(this.certificate.Id);
      void this.pdfPreviewController.openPdfPreview(blob);
    } catch (error) {
      this.errorService.handleError(error);
    }
    this.previewProgress = false;
  }

  protected approveProgress = false;
  protected async approveCertificate() {
    try {
      if (this.isLocked || !this.certificate.Id) {
        throw new Error('Certificate is locked or not found');
      }

      const approved = await this.confirmation.confirmYesNo('general.approve', 'mooring.confirmApprovalOfCertificate');
      if (approved) {
        this.approveProgress = true;
        await this.mooringCertificateService.approve(this.certificate.Id);
        await this.setup(this.certificate.Id);
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
    this.approveProgress = false;
  }

  private async getCertificate(id: number) {
    try {
      this.certificate = await this.mooringCertificateService.get(id);
      if (this.certificate.OverrideCertificateProducer) {
        this.certificateProducer = this.certificate.OverrideCertificateProducer;
      }
      this.isLocked = !!this?.certificate?.ApprovedAt;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async setMetadata(articleProductId: number) {
    this.article = await this.mooringArticleProductService.article(articleProductId);
    const supplier = await this.supplierService.get(this.article.SupplierId);
    const product = this.article.ArticleProducts.find((x) => x.Id == articleProductId);

    const primaryDimensionType = categoryDimensionTypes.find((x) => x.value == this.article.PrimaryDimensionType);
    const secondaryDimensionType = categoryDimensionTypes.find((x) => x.value == this.article.SecondaryDimensionType);

    let dimension = `${this.article.PrimaryDimension} ${this.t.tr(primaryDimensionType?.labelShort)}`;
    if (secondaryDimensionType) {
      dimension += ` / ${this.article.SecondaryDimension} ${this.t.tr(secondaryDimensionType?.labelShort)}`;
    }

    this.metadata = {
      typeName: this.article.TypeName,
      articleNo: product.ArticleNumber,
      dimension,
      articleName: product.ProductName,
      categoryName: this.article.CategoryName,
      supplierName: supplier?.Name || '',
    };

    if (!this.form.MooringStationId && this.state.user.MooringStationId) {
      this.form.MooringStationId = this.state.user.MooringStationId;
    }

    this.form.MooringStationId = this.state.user.MooringStationId;

    this.certificateProducer = this.certificateProducer ?? supplier.CertificateProducer;
    this.isMorenotCertificate = this.certificateProducer == CertificateProducer.MORENOT;
  }

  protected async onArticleUpdate({ ArticleProductId, OverrideCertificateProducer }: Partial<CertificateFormData>) {
    if (OverrideCertificateProducer) {
      this.certificateProducer = OverrideCertificateProducer;
    }
    await this.setMetadata(ArticleProductId);

    this.form.ArticleProductId = ArticleProductId;
    this.form.SupplierId = this.article.SupplierId;
    this.form.OverrideCertificateProducer = OverrideCertificateProducer;

    this.isEditingMetadata = false;
    this.isMorenotCertificate = this.certificateProducer == CertificateProducer.MORENOT;
  }

  // Aurelia lifecycle
  protected activate(model: { certificateId: number }) {
    if (model?.certificateId) {
      void this.setup(model.certificateId);
    }
  }

  protected async setup(certificateId?: number) {
    if (certificateId) {
      await this.getCertificate(certificateId);
      await this.setMetadata(this.certificate.ArticleProductId);

      this.form = {
        Id: this.certificate.Id,
        SupplierId: this.certificate.SupplierId,
        ArticleProductId: this.certificate.ArticleProductId,
        MooringStationId: this.certificate.MooringStationId,
        Quantity: this.certificate.Quantity,
        BatchNumber: this.certificate.BatchNumber,
        BatchNumberTo: this.certificate.BatchNumberTo,
        DateOfGoodsReceipt: this.certificate.DateOfGoodsReceipt,
        ReliabilityClass: this.certificate.ReliabilityClass,
        InternalOrderNumber: this.certificate.InternalOrderNumber,
        CertificateAttachment: undefined,
        CertificateAttachmentId: this.certificate.CertificateAttachmentId,
        ProducerDocumentationAttachment: undefined,
        ProducerDocumentationAttachmentId: this.certificate.ProducerDocumentationAttachmentId,
        TemplateId: this.certificate.TemplateId,
        OverrideCertificateProducer: this.certificate.OverrideCertificateProducer,
      };
      this.isEditingMetadata = false;
    }
  }

  protected onCancel() {
    void this.dialogController.close(true);
  }

  protected async onDelete() {
    try {
      await this.mooringCertificateService.delete(this.certificate.Id);
      this.toastService.showSuccess('general.deleted');
    } catch (error) {
      this.errorService.handleError(error);
    }
    this.onCancel();
  }

  protected unlockProgress = false;
  protected async unlockCertificate() {
    try {
      const approve = await this.confirmation.confirmYesNo('general.unlock', 'mooring.confirmUnlockOfCertificate');
      if (approve) {
        this.unlockProgress = true;
        await this.mooringCertificateService.unlock(this.certificate.Id);
        this.pubSub.publish('list-entity:updated', null);
        await this.setup(this.certificate.Id);
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
    this.unlockProgress = false;
  }

  protected async onCertificateDetailsUpdate(data: CertificateFormData) {
    this.form.DateOfGoodsReceipt = data.DateOfGoodsReceipt;
    this.form.BatchNumber = data.BatchNumber;
    this.form.BatchNumberTo = data.BatchNumberTo;
    this.form.InternalOrderNumber = data.InternalOrderNumber;
    this.form.Quantity = data.Quantity;
    this.form.MooringStationId = data.MooringStationId;
    this.form.TemplateId = data.TemplateId;
    this.form.CertificateAttachment = data.CertificateAttachment;
    this.form.ReliabilityClass = data.ReliabilityClass;
    this.form.CertificateAttachmentId = data.CertificateAttachmentId;
    this.form.ProducerDocumentationAttachment = data.ProducerDocumentationAttachment;
    this.form.ProducerDocumentationAttachmentId = data.ProducerDocumentationAttachmentId;
    this.form.OverrideCertificateProducer = this.certificateProducer;

    if (this.certificate.Id) {
      this.form.Id = this.certificate.Id;
    }
    await this.onSaveCertificate(this.form as CertificateFormData);
  }

  protected async onSaveCertificate(data: CertificateFormData) {
    this.isSaving = true;
    let id = data.Id;
    try {
      // validate id
      if (data.Id) {
        await this.mooringCertificateService.update(data as CreateUpdateMooringCertificate, data.Id);
        this.pubSub.publish('list-entity:updated', null);
      } else {
        const response = await this.mooringCertificateService.create(data);
        id = response.Id;
        this.pubSub.publish('list-entity:created', null);
      }
      await this.setup(id);
      this.toastService.showSuccess('general.saved');
    } catch (error) {
      this.errorService.handleError(error);
    }
    this.isSaving = false;
  }
}
